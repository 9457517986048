import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Form, Formik } from "formik";

import useAuthService from "../../hooks/AuthService";

import Vine from "components/svg/Vine";
import Carousel from "components/Carousel";
import Media from "components/Media";
import Chateau from "components/svg/Chateau";
import MobileNav from "components/Navigation/MobileNav";
import DesktopNav from "components/Navigation/DesktopNav";
import { GridContainer, GridItem } from "components/Grid";
import TextInput from "components/Form/TextInput";
import Button from "components/Button";
import LoadingWheel from "components/LoadingWheel";
import CarouselStack from "components/CarouselStack";
import ToggleInput from "components/Form/ToggleInput";
import fetchApi from "hooks/fetchApi";
import AuthContext from "context/Auth";
import TextArea from "components/Form/TextArea";
import SelectInput from "components/Form/SelectInput";
import Popup from "components/Popup";

const scrollIntoViewWithOffset = (element: { getBoundingClientRect: () => { (): any; new(): any; top: number; }; }, offset: number) => {
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const Main = () => {
  const navigate = useNavigate();
  const { logout } = useAuthService();
  const { user } = useContext(AuthContext);

  const [navToggle,setNavToggle] = useState<boolean>(false);

  const [searchError,setSearchError] = useState<string>("");
  const [rsvpError,setRsvpError] = useState<string>("");
  const [rsvpSuccess,setRsvpSuccess] = useState<string>("");

  const [account,setAccount] = useState<any>(false);
  const [accountRsvps,setAccountRsvps] = useState<any[]>([]);

  const [isPopMen, setIsPopMen] = useState<boolean>(false);
  const [isPopWomen, setIsPopWomen] = useState<boolean>(false);


  const findAccount = async (values: any) => {
    setSearchError("");
    setAccount(false);

    const res = await fetchApi(`/users/by-email`, {}, {
      method: "POST",
      body: JSON.stringify({ email : values.search })
    },{ "Authorization" : `Bearer ${user.token}`});
    if(res?.id){
      setAccount(res);
      setAccountRsvps(res.rsvps || [])
    } else {
      setSearchError(res.message || "Unable to locate email");
      setAccount(false);
      setAccountRsvps([]);
    }
  }

  const addToRsvps = () => {
    if(accountRsvps.length < account.allowedRsvp){
      setAccountRsvps((old) => {
        const arr = [...old,{ 'name' : '' }]
        return arr;
      })
    }
  }

  const removeFromRsvps = (index: number) => {
    setAccountRsvps((old) => {
      const arr = [...old]
      arr.splice(index, 1);
      return arr;
    })
  }

  const submitRsvps = async (values: any) => {
    setRsvpError("");
    setRsvpSuccess("")
    const res = await fetchApi(`/users/${account?.id}/rsvps`, {}, {
      method: "POST",
      body: JSON.stringify({ rsvps : values.rsvps })
    },{ "Authorization" : `Bearer ${user.token}`});
    if(res?.success){
      if(res.user){
        setAccount(res?.user);
        setAccountRsvps(res?.user?.rsvps || [])
      }
      setRsvpSuccess(res.message || "RSVP updated");
      setTimeout(() => setRsvpSuccess(""), 3000);
    } else {
      setRsvpError(res.message || "Error saving 1 or more RSVPS");
    }
  }

  const homeRef = useRef<any>(null);
  const venueRef = useRef<any>(null);
  const itineraryRef = useRef<any>(null);
  const rsvpRef = useRef<any>(null);
  const accommodationsRef = useRef<any>(null);

  const scrollToSection = (section: any) => {
    if (section) {      
      setNavToggle(true);
      scrollIntoViewWithOffset(section,75);
      setTimeout(() => setNavToggle(false), 500);
    }
  };

  const navItems: any[] = [
    <span key={1} onClick={() => scrollToSection(homeRef.current)}>Home</span>,
    <span key={2} onClick={() => scrollToSection(venueRef.current)}>Venue</span>,
    <span key={3} onClick={() => scrollToSection(itineraryRef.current)}>Itinerary</span>,
    <span key={5} onClick={() => scrollToSection(accommodationsRef.current)}>Accommodations</span>,
    <span key={6} onClick={() => scrollToSection(rsvpRef.current)}>RSVP</span>,
    <span key={7} onClick={() => { logout(); navigate('/'); }}>Logout</span>
  ];

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME + " | Wedding"           
  },[])

  return (    
    <div className={styles.wrapper}>
      <MobileNav items={navItems} toggle={navToggle} />
      <DesktopNav items={navItems} />
      
      <div className={styles.header} ref={homeRef}>      
        <div className="container">                                    
          <p className="text-color-primary ff-goldenbook-up mb-4">We invite you to celebrate</p>
          <h2 className={[styles.fsSmall,"ff-p22zaner"].join(" ")}>the Wedding of</h2>
          <h1 className={styles.headerBrand}>Jennifer <span className={[styles.fsSmall,"ff-p22zaner"].join(" ")}>and</span> David</h1>          
          <p className="text-color-primary ff-goldenbook-up">May 31, 2025<br />Portets, France</p>  
        </div>        
      </div>      
      <div className={styles.body}>            
        <div className="container">  
          <div className="py-5">  
            <GridContainer className={styles.hero1}>
              <GridItem startLg={1} endLg={4}>
                <Media ratio="2x3" url="/images/us/header-1.jpg" alternativeText="us"/>
              </GridItem>
              <GridItem startLg={4} endLg={12}>    
                <Media ratio="3x2" url="/images/us/header-2.jpg" alternativeText="us"/>                  
              </GridItem>              
            </GridContainer>
          </div>  
          <div className={styles.separator}>
            <Vine fill="var(--color-wine)" style={{opacity : .4}} />
          </div>               
          <div className="py-5" ref={venueRef}>                        
            <GridContainer className={styles.mainGrid}>
              <GridItem startLg={1} endLg={7}>
                <h4 className="text-color-secondary">The Venue</h4>
                <h2><span className="ff-p22zaner">C</span>hateau de <span className="ff-p22zaner">L</span>&apos;Hospital</h2>
                <p>8 Rue de L’Hospital<br />33640 Portets, France</p>
                <p>25 minutes from Bordeaux via the A62</p>
                <p>5 minutes walk from Portets train station</p>
              </GridItem>
              <GridItem start={2} end={11} startLg={7} endLg={11}>
                <CarouselStack items={[
                  <div key={1} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/1.jpg" alternativeText="us"/></div>,
                  <div key={2} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/3.jpg" alternativeText="us"/></div>,
                  <div key={3} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/5.jpg" alternativeText="us"/></div>,
                ]} />
              </GridItem>          
            </GridContainer>
          </div>
        </div>    
        <div className={styles.separator}>
          <Vine fill="var(--color-wine)" style={{opacity : .4}} />
        </div>             
        <div className="container">           
          <div className="py-5" ref={itineraryRef}>
            <h2>The <span className="ff-p22zaner">I</span>tinerary</h2>    
            <p>To allow all guests to relax and enjoy themselves, we have chosen to make our special weekend an adult-only occasion (with the exception of direct family).</p>
            <p>We appreciate your understanding. Enjoy the weekend off and celebrate with us!</p>
            <div className={styles.itineraryItems}>
              <div className={styles.itineraryItem}>
                <div>
                  <h4 className="pb-2"><span className="ff-p22zaner">Friday</span><br /><span className="text-color-secondary">May 30, 2025</span></h4>                    
                  <p>Join us as we kick off the celebrations with welcome drinks and dinner at the Château</p>                 
                  <p>6 PM: Welcome Dinner</p>   
                </div>
              </div>
              <div className={styles.itineraryItem}>
                <div>
                  <h4 className="pb-2"><span className="ff-p22zaner">Saturday</span><br /><span className="text-color-secondary">May 31, 2025</span></h4>
                  <p>The big day is here! The ceremony kicks off at 4 PM, followed by cocktails, dinner, and dancing until the wee hours. Bring your dancing shoes!</p>  
                  <p>4 PM: Ceremony</p>
                </div>
              </div>
              <div className={styles.itineraryItem}>
                <div>
                  <h4 className="pb-2"><span className="ff-p22zaner">Sunday</span><br /><span className="text-color-secondary">June 1, 2025</span></h4>
                  <p>A fond farewell with brunch</p>
                  <p>1 PM: Brunch</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.separator}>
            <Vine fill="var(--color-wine)" style={{opacity : .4}} />
          </div>
          <div className="py-5">
            <GridContainer className={[styles.mainGrid,styles.reversedGrid].join(" ")}>
              <GridItem start={2} end={11} startLg={1} endLg={5}>
                <CarouselStack items={[
                  <div key={1} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/2.jpg" alternativeText="us"/></div>,
                  <div key={2} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/4.jpg" alternativeText="us"/></div>,
                  <div key={3} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/6.jpg" alternativeText="us"/></div>,
                ]} />
              </GridItem>
              <GridItem startLg={6} endLg={11}>
                <h2>The <span className="ff-p22zaner">D</span>ress Code</h2>   
                <p>&nbsp;</p>
                <h4>Formal Attire or Black-Tie Optional</h4>
                <div className="d-flex gap-4">
                  <p><Button type="button" scheme="plain" onClick={() => setIsPopMen(true)}><span className="text-underline">Inspiration Men</span></Button></p>
                  <p><Button type="button" scheme="plain" onClick={() => setIsPopWomen(true)}><span className="text-underline">Inspiration Women</span></Button></p>
                </div>                
              </GridItem>                          
            </GridContainer> 
            <Popup isOpen={isPopMen} onClose={() => setIsPopMen(false)}>
              <GridContainer columns={12}>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/1.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/2.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/3.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/4.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/4-1.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/4-2.jpg" alternativeText="example"/>
                </GridItem>
              </GridContainer>
            </Popup>
            <Popup isOpen={isPopWomen} onClose={() => setIsPopWomen(false)}>
              <GridContainer columns={12}>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/5.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/6.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/7.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/8.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={1} end={7} startLg={1} endLg={7}>
                  <Media url="/images/examples/9.jpg" alternativeText="example"/>
                </GridItem>
                <GridItem start={7} end={13} startLg={7} endLg={13}>
                  <Media url="/images/examples/10.jpg" alternativeText="example"/>
                </GridItem>
              </GridContainer>
            </Popup>
          </div>          
          <div className={styles.separator}>
            <Vine fill="var(--color-wine)" style={{opacity : .4}} />
          </div>
          <div className="py-5">
            <h2>The <span className="ff-p22zaner">W</span>edding Party</h2>
            <GridContainer>
              <GridItem startLg={1} endLg={11}>
                <Carousel columns={4} items={[
                    <div key={1} className={styles.stampEdge}><Media ratio="4x5" url="/images/party/IMG_20240818_220007-2.jpg" alternativeText="Grace"/><span className="d-block text-center py-2"><span className="ff-p22zaner">G</span>race</span></div>,
                    <div key={2} className={styles.stampEdge}><Media ratio="4x5" url="/images/party/IMG_0816-2.jpg" alternativeText="Vanessa"/><span className="d-block text-center py-2"><span className="ff-p22zaner">V</span>anessa</span></div>,
                    <div key={3} className={styles.stampEdge}><Media ratio="4x5" url="/images/party/IMG_1230816-2.jpg" alternativeText="Rebecca"/><span className="d-block text-center py-2"><span className="ff-p22zaner">R</span>ebecca</span></div>,
                    <div key={4} className={styles.stampEdge}><Media ratio="4x5" url="/images/party/IMG_12356346.jpg" alternativeText="Joshua"/><span className="d-block text-center py-2"><span className="ff-p22zaner">J</span>oshua</span></div>,
                  ]}  
                />
              </GridItem>
            </GridContainer>
          </div> 
          <div className={styles.separator}>
            <Vine fill="var(--color-wine)" style={{opacity : .4}} />
          </div>
          <div className="py-5" ref={accommodationsRef}>
            <GridContainer className={styles.mainGrid}>
              <GridItem startLg={1} endLg={6}>
                <h2><span className="ff-p22zaner">A</span>ccommodations</h2>
                <h4>Nearby locations</h4>
                <p><a href="https://www.booking.com/hotel/fr/restaurant-rapin.html" target="_blank" rel="noreferrer">Hôtel Rapin Hôtel</a></p>
                <p><a href="https://www.booking.com/hotel/fr/ma-maison-familiale-portesienne.html" target="_blank" rel="noreferrer">Ma Maison Familiale Portesienne</a></p>
                <p><a href="https://www.sources-coulon.fr/en/prices/" target="_blank" rel="noreferrer">Les Sources de Coulon</a></p>
                <p><a href="https://chateau-renon.fr/en/guest-room/" target="_blank" rel="noreferrer">Château Renon</a></p>
                <p><a href="https://sources-caudalie.com/en/les-sources-de-caudalie/" target="_blank" rel="noreferrer">Les Sources de Caudalie</a></p>
                <h4>AirBnB Search</h4>
                <p><a href="https://www.airbnb.com/s/Portets--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-11-01&monthly_length=3&monthly_end_date=2025-02-01&price_filter_input_type=0&channel=EXPLORE&query=Portets%2C%20France&place_id=ChIJyYpRJrkXVQ0RcNwWSBdlBgQ&location_bb=QjLUib7KenhCMpi2vuoW8g%3D%3D&date_picker_type=calendar&checkin=2025-05-30&checkout=2025-06-02&adults=2&source=structured_search_input_header&search_type=autocomplete_click" target="_blank" rel="noreferrer">Search Example</a></p>
              </GridItem>              
              <GridItem start={2} end={11} startLg={7} endLg={11}>
                <CarouselStack items={[
                  <div key={1} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/7.jpg" alternativeText="us"/></div>,
                  <div key={2} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/8.jpg" alternativeText="us"/></div>,
                  <div key={3} className={styles.stampEdge}><Media ratio="5x4" url="/images/us/9.jpg" alternativeText="us"/></div>,
                ]} />
              </GridItem>
            </GridContainer>
          </div> 
          <div className={styles.separator}>
            <Vine fill="var(--color-wine)" style={{opacity : .4}} />
          </div>          
          <div className="py-5" ref={rsvpRef}>
            <GridContainer>
              <GridItem startLg={3} endLg={9}>              
                <h2 className="text-center"><span className="ff-p22zaner">R</span>SVP</h2>
                <p className="text-center">We kindly request that all guests RSVP by February 22, 2025, even if you are unable to attend.</p>
                <p className="text-center">If we have not heard from you by the deadline, we will be sorry to miss you at the wedding.</p>
                <div className="mt-4">
                  <h4 className="text-center">Please provide your email address</h4>
                  <Formik initialValues={{search : ""}} onSubmit={findAccount}>
                    {({ isSubmitting, values, setFieldValue }: any) => (
                      <Form noValidate autoComplete="off" className="d-flex">  
                        <TextInput 
                          type="email" 
                          name="search"
                          placeholder="Email Address"
                          value={values.search}
                          onChange={(val: any) => setFieldValue('search',val)}
                          error={searchError}
                        />
                        <div>
                          <Button type="submit" disabled={isSubmitting}>{isSubmitting ? <LoadingWheel width="20px" light={true} /> : "Go!"}</Button>                          
                        </div>                                              
                      </Form>
                    )}
                  </Formik>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {account &&
          <div className={styles.rsvpBox}>
            <div className="container">           
              <GridContainer>
                <GridItem startLg={3} endLg={9}>    
                  <Formik initialValues={{rsvps : accountRsvps || []}} enableReinitialize onSubmit={submitRsvps}>
                    {({ isSubmitting, values, errors, submitCount, setFieldValue }: any) => (
                      <Form noValidate autoComplete="off" className={styles.form}>  
                        <div className="d-flex justify-content-center mb-3">
                          <Button type="submit" disabled={isSubmitting}>{isSubmitting ? <LoadingWheel width="20px" light={true} /> : "Submit RSVP"}</Button>
                        </div> 
                        {rsvpSuccess && <p className="fw-600 text-color-success text-center">{rsvpSuccess}</p>}
                        {rsvpError && <p className="fw-600 text-color-danger text-center">{rsvpError}</p>}
                        {(!Array.isArray(values?.rsvps) || values?.rsvps?.length < account?.allowedRsvp) && 
                          <p><Button type="button" scheme="secondary" className="text-no-wrap" onClick={addToRsvps}>Add guest</Button></p>
                        } 
                        <div className={styles.rsvpCards}>
                          {(values?.rsvps || []).map((rsvp: any, index: number) => (
                            <div key={index} className={styles.rsvpCard}>
                              <h4>Name</h4>                                    
                              <TextInput
                                name={`rsvps[${index}][name]`}
                                placeholder="Name"   
                                value={rsvp?.name}         
                                onChange={(val: any) => setFieldValue(`rsvps[${index}][name]`,val)}                        
                                error={errors?.rsvps?.[index]?.name && submitCount > 0 ? errors.rsvps?.[index]?.name : null}
                              /> 
                              <h4>What days will you be attending</h4>
                              <div className="d-flex gap-3 flex-wrap">
                                <ToggleInput 
                                  label="Attending Friday"
                                  checked={rsvp?.fridayConfirmed}
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  onChange={(val: any) => setFieldValue(`rsvps[${index}][fridayConfirmed]`,val)}                        
                                /> 
                                <ToggleInput 
                                  label="Attending Saturday"
                                  checked={rsvp?.saturdayConfirmed}
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  onChange={(val: any) => setFieldValue(`rsvps[${index}][saturdayConfirmed]`,val)}                        
                                />  
                                <ToggleInput 
                                  label="Attending Sunday"
                                  checked={rsvp?.sundayConfirmed}
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  onChange={(val: any) => setFieldValue(`rsvps[${index}][sundayConfirmed]`,val)}                        
                                /> 
                              </div>
                              {rsvp?.saturdayConfirmed &&
                              <>
                                <h4>Please select your entree for dinner on Saturday</h4>
                                <SelectInput     
                                  name={`rsvps[${index}][dinner]`}
                                  value={rsvp?.dinner}
                                  options={[
                                    { value : "Sea Bass", label : "Sea Bass"},
                                    { value : "Veal", label : "Veal"},
                                    { value : "Vegetarian", label : "Vegetarian"}
                                  ]}
                                  onChange={(val: any) => setFieldValue(`rsvps[${index}][dinner]`,val)}
                                /> 
                              </>
                              }
                              {!(!rsvp?.fridayConfirmed && !rsvp?.saturdayConfirmed && !rsvp?.sundayConfirmed) &&
                                <>
                                  <h4>Are there any food allergies or restrictions we should be aware of?</h4>
                                  <ToggleInput                                   
                                    checked={rsvp?.allergies}
                                    labelTrue="Yes"
                                    labelFalse="No"
                                    onChange={(val: any) => setFieldValue(`rsvps[${index}][allergies]`,val)}                        
                                  /> 
                                  {rsvp?.allergies && <>
                                    <TextArea
                                      label="Please provide as much information about your food allergies or restrictions"
                                      name={`rsvps[${index}][allergiesDetails]`}
                                      rows={3}
                                      value={rsvp?.allergies ? rsvp?.allergiesDetails : ""}         
                                      onChange={(val: any) => setFieldValue(`rsvps[${index}][allergiesDetails]`,val)}                        
                                      error={errors?.rsvps?.[index]?.allergiesDetails && submitCount > 0 ? errors.rsvps?.[index]?.allergiesDetails : null}
                                    /> 
                                  </>}
                                  {index === 0 && <>
                                    <TextArea
                                      label="Please let us know where you will be staying (you can always come back and update)"
                                      name={`rsvps[${index}][accommodations]`}
                                      rows={3}
                                      value={rsvp?.accommodations || ""}         
                                      onChange={(val: any) => setFieldValue(`rsvps[${index}][accommodations]`,val)}                        
                                      error={errors?.rsvps?.[index]?.accommodations && submitCount > 0 ? errors.rsvps?.[index]?.accommodations : null}
                                    />                
                                  </>}                 
                                </>
                              }
                            </div>
                          ))}   
                        </div> 
                        <div className="d-flex justify-content-center">
                          <Button type="submit" disabled={isSubmitting}>{isSubmitting ? <LoadingWheel width="20px" light={true} /> : "Submit RSVP"}</Button>
                        </div> 
                        {rsvpSuccess && <p className="fw-600 text-color-success text-center">{rsvpSuccess}</p>}
                        {rsvpError && <p className="fw-600 text-color-danger text-center">{rsvpError}</p>}                   
                      </Form>
                    )}
                  </Formik>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        }                        
      </div> 
      <div className={styles.footer}>
        <Chateau width="100%" fill="var(--color-wine)" style={{maxWidth : '500px', opacity: .15}}  />
        <p>KuderTak.com - 2025</p>
      </div>      
    </div>
  );            
};

export default Main;